/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-ignore
import {useEffect} from '@wordpress/element';
// @ts-ignore
import SCSS from './SVG.module.scss';
// @ts-ignore
import classnames from 'classnames';
// @ts-ignore
import {useSelector} from 'react-redux';
import {selectUnit, IUnit} from '../../store/availabilitySlice';

declare const window: any;

const _ = require('lodash');

const Units = () => {
	const UNITS = useSelector(selectUnit);
	const checkUnitClass = (unit: string) => {
		const u: IUnit = _.find(UNITS, {unit});
		const classes: Array<any> = [SCSS.Unit];
		if (UNITS.length > 0) {
			classes.push({
				[`${SCSS.Hold}`]: u.status === 'hold',
				[`${SCSS.Sold}`]: u.status === 'sold',
				[`${SCSS.Unavailable}`]: u.status === 'unavailable',
			});
		}
		return classnames(classes);
	};

	useEffect(() => {
		const pathArray = window.location.pathname.split('/');
		const hashArray = window.location.hash.split('/');
		let index: number;

		// console.log(window.location.hostname, pathArray, hashArray);
		if (pathArray[1] === 'townhomes') {
			// @ts-ignore
			switch (hashArray[1]) {
				case 'a':
					index = 1;
					break;
				case 'b':
					index = 2;
					break;
				case 'd':
					index = 3;
					break;
				// case 'd2':
				// 	index = 4;
				// 	break;
				case 'd3':
					index = 4;
					break;
				case 'e':
					index = 5;
					break;
				case 'e1':
					index = 6;
					break;
				case 'e2':
					index = 7;
					break;
				case 'e3':
					index = 8;
					break;
				case 'u1':
					index = 9;
					break;
				case 'u2':
					index = 10;
					break;
				default:
					index = 1;
					break;
			}
			// console.log('index =', index);
			try {
				window.revapi41.revshowslide(index);
			} catch (e) {
				// console.log(e);
			}
		}
	});

	const onclickUnit = (plan: string) => {
		const pathArray = window.location.pathname.split('/');
		let index: number;
		// console.log(window.location.hostname, pathArray);
		if (pathArray[1] === 'availability') {
			window.location.href = `https://${window.location.hostname}/townhomes/#floorplan-section/${plan}`;
		}

		if (pathArray[1] === 'townhomes') {
			switch (plan) {
				case 'a':
					index = 1;
					break;
				case 'b':
					index = 2;
					break;
				case 'd':
					index = 3;
					break;
				// case 'd2':
				// 	index = 4;
				// 	break;
				case 'd3':
					index = 4;
					break;
				case 'e':
					index = 5;
					break;
				case 'e1':
					index = 6;
					break;
				case 'e2':
					index = 7;
					break;
				case 'e3':
					index = 8;
					break;
				case 'u1':
					index = 9;
					break;
				case 'u2':
					index = 10;
					break;
				default:
					index = 1;
					break;
			}
			window.location.href = `https://${window.location.hostname}/townhomes/#floorplan-section`;
			// console.log('index =', index);
			try {
				window.revapi41.revshowslide(index);
			} catch (e) {
				// console.log(e);
			}
		}
	};

	return (
		<g id='UNITS' className={SCSS.Units}>
			<g id='UNIT-101-U2' className={checkUnitClass('101')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u2')}
					points='888.33,420.73 887.36,419.72 896.68,410.77 895.53,409.59 900.17,405.13 868.22,371.86 
			846.58,392.65 880.64,428.12 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 868.5263 396.0233)'
					>
						U2
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 865.2476 407.1879)'
					>
						101
					</text>
				</g>
			</g>
			<g id='UNIT-102-D3' className={checkUnitClass('102')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='931.8,531.1 934.73,533.82 946.89,520.71 913.07,489.34 908.23,494.56 899.37,486.34 884.02,502.89 
			923.77,539.76 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 910.7847 510.2669)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 906.4348 521.4315)'
					>
						102
					</text>
				</g>
			</g>
			<g id='UNIT-103-U1' className={checkUnitClass('103')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u1')}
					points='862.26,441.53 863.4,442.72 872.72,433.78 873.69,434.79 880.64,428.12 846.58,392.65 825.5,412.89 
			857.45,446.16 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 848.3066 416.5766)'
					>
						U1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 842.8575 427.7412)'
					>
						103
					</text>
				</g>
			</g>
			<g id='UNIT-104-D' className={checkUnitClass('104')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d')}
					points='918.45,551.37 915.52,548.65 923.77,539.76 884.02,502.89 868.95,519.13 877.81,527.36 
			873.18,532.35 907,563.72 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 891.4158 531.7804)'
					>
						D
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 886.6473 542.9451)'
					>
						104
					</text>
				</g>
			</g>
			<g id='UNIT-105-U1' className={checkUnitClass('105')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u1')}
					points='825.5,412.89 804.83,432.74 838.89,468.21 846.14,461.25 845.17,460.23 854.48,451.29 853.34,450.1 
			857.45,446.16 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 827.1104 437.1302)'
					>
						U1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 821.6071 448.2948)'
					>
						105
					</text>
				</g>
			</g>
			<g id='UNIT-106-D' className={checkUnitClass('106')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d')}
					points='895.19,576.45 907,563.72 873.18,532.35 868.57,537.32 859.91,529.29 844.83,545.55 884.36,582.23 
			892.25,573.73 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 872.0239 553.2939)'
					>
						D
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 867.4175 564.4586)'
					>
						106
					</text>
				</g>
			</g>
			<g id='UNIT-107-U2' className={checkUnitClass('107')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u2')}
					points='783.31,453.4 815.26,486.67 820.07,482.05 821.21,483.24 830.53,474.29 831.5,475.31 838.89,468.21 
			804.83,432.74 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 804.9371 457.6837)'
					>
						U2
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 800.4764 468.8483)'
					>
						107
					</text>
				</g>
			</g>
			<g id='UNIT-108-D3' className={checkUnitClass('108')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='829.49,562.08 838.15,570.11 833.01,575.65 866.83,607.02 878.91,594 875.97,591.28 884.36,582.23 
			844.83,545.55 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 852.6101 574.8075)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 848.2182 585.9722)'
					>
						108
					</text>
				</g>
			</g>
			<g id='UNIT-109-U2' className={checkUnitClass('109')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u2')}
					points='795.91,515.56 794.81,514.69 802.78,504.53 801.49,503.52 805.46,498.46 769.18,469.97 
			750.65,493.57 789.33,523.95 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 772.3271 494.0985)'
					>
						U2
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 767.6085 505.2632)'
					>
						109
					</text>
				</g>
			</g>
			<g id='UNIT-110-D3' className={checkUnitClass('110')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='845.3,627.96 848.56,630.27 858.91,615.7 821.3,588.99 817.18,594.8 807.32,587.8 794.25,606.2 
			838.46,637.59 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 824.84 609.9453)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 821.6961 621.11)'
					>
						110
					</text>
				</g>
			</g>
			<g id='UNIT-111-U1' className={checkUnitClass('111')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u1')}
					points='773,539.79 774.3,540.81 782.27,530.65 783.38,531.52 789.33,523.95 750.65,493.57 732.6,516.56 
			768.88,545.04 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 755.4832 517.3617)'
					>
						U1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 753.0762 528.5264)'
					>
						111
					</text>
				</g>
			</g>
			<g id='UNIT-112-D' className={checkUnitClass('112')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d')}
					points='834.7,649.79 831.44,647.48 838.46,637.59 794.25,606.2 781.42,624.27 791.28,631.27 787.34,636.82 
			824.95,663.52 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 807.1671 633.6268)'
					>
						D
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 804.6428 644.7915)'
					>
						112
					</text>
				</g>
			</g>
			<g id='UNIT-113-U1' className={checkUnitClass('113')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u1')}
					points='732.6,516.56 714.9,539.1 753.58,569.47 759.79,561.57 758.68,560.7 766.66,550.54 765.36,549.52 
			768.88,545.04 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 737.6628 540.6249)'
					>
						U1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 734.0613 551.7896)'
					>
						113
					</text>
				</g>
			</g>
			<g id='UNIT-114-D' className={checkUnitClass('114')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d')}
					points='814.9,677.68 824.95,663.52 787.34,636.82 783.42,642.35 773.79,635.52 760.95,653.61 804.92,684.83 
			811.64,675.37 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 789.4716 657.3085)'
					>
						D
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 786.5514 668.4731)'
					>
						114
					</text>
				</g>
			</g>
			<g id='UNIT-115-U2' className={checkUnitClass('115')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u2')}
					points='696.48,562.56 732.76,591.05 736.88,585.8 738.17,586.82 746.15,576.66 747.25,577.53 753.58,569.47 
			714.9,539.1 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 718.8654 563.8879)'
					>
						U2
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 716.1869 575.0526)'
					>
						115
					</text>
				</g>
			</g>
			<g id='UNIT-116-D3' className={checkUnitClass('116')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='747.89,671.99 757.52,678.82 753.15,684.98 790.76,711.68 801.04,697.2 797.78,694.89 804.92,684.83 
			760.95,653.61 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 771.7539 680.9899)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 769.0177 692.1545)'
					>
						116
					</text>
				</g>
			</g>
			<g id='UNIT-117-U2' className={checkUnitClass('117')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u2')}
					points='716.24,621.64 715.06,620.87 722.09,610.04 720.71,609.14 724.21,603.75 685.52,578.63 669.18,603.8 
			710.43,630.58 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 691.441 601.6904)'
					>
						U2
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 688.8284 612.8551)'
					>
						117
					</text>
				</g>
			</g>
			<g id='UNIT-118-D3' className={checkUnitClass('118')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='771.48,734.95 774.85,737.11 784.51,722.07 745.71,697.13 741.86,703.12 731.69,696.58 
			719.49,715.57 765.1,744.88 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 747.7802 716.3265)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 745.2363 727.4912)'
					>
						118
					</text>
				</g>
			</g>
			<g id='UNIT-119-U1' className={checkUnitClass('119')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u1')}
					points='695.59,647.83 696.98,648.73 704.01,637.89 705.19,638.66 710.43,630.58 669.18,603.8 653.27,628.31 
			691.96,653.43 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 676.6056 626.2443)'
					>
						U1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 672.7581 637.4089)'
					>
						119
					</text>
				</g>
			</g>
			<g id='UNIT-120-D' className={checkUnitClass('120')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d')}
					points='761.9,757.25 758.54,755.09 765.1,744.88 719.49,715.57 707.5,734.21 717.68,740.75 714,746.47 
			752.8,771.41 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 731.9906 740.8804)'
					>
						D
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 727.6182 752.045)'
					>
						120
					</text>
				</g>
			</g>
			<g id='UNIT-121-U1' className={checkUnitClass('121')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u1')}
					points='653.27,628.31 637.67,652.35 678.92,679.13 684.39,670.7 683.21,669.93 690.24,659.1 688.86,658.2 
			691.96,653.43 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 660.7931 650.7992)'
					>
						U1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 657.18 661.9638)'
					>
						121
					</text>
				</g>
			</g>
			<g id='UNIT-122-D' className={checkUnitClass('122')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d')}
					points='743.41,786.02 752.8,771.41 714,746.47 710.33,752.18 700.4,745.8 688.4,764.46 733.77,793.62 
			740.04,783.86 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 716.1781 765.4353)'
					>
						D
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 712.4478 776.5999)'
					>
						122
					</text>
				</g>
			</g>
			<g id='UNIT-123-U2' className={checkUnitClass('123')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u2')}
					points='621.42,677.37 660.11,702.49 663.74,696.89 665.13,697.79 672.16,686.96 673.34,687.72 
			678.92,679.13 637.67,652.35 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 644.0043 675.3533)'
					>
						U2
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 640.1738 686.5179)'
					>
						123
					</text>
				</g>
			</g>
			<g id='UNIT-124-D3' className={checkUnitClass('124')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='676.21,783.42 686.14,789.81 682.06,796.16 720.86,821.1 730.46,806.16 727.1,804 733.77,793.62 
			688.4,764.46 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 700.3434 789.9894)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 696.2395 801.1541)'
					>
						124
					</text>
				</g>
			</g>
			<g id='UNIT-125-U2' className={checkUnitClass('125')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u2')}
					points='643.78,733.05 642.6,732.29 649.64,721.46 648.25,720.56 651.76,715.17 613.07,690.05 596.73,715.22 
			637.98,742 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 617.9141 711.6838)'
					>
						U2
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 614.0294 722.8485)'
					>
						125
					</text>
				</g>
			</g>
			<g id='UNIT-126-D3' className={checkUnitClass('126')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='701,844.16 704.37,846.32 714.03,831.28 675.23,806.35 671.38,812.34 661.21,805.8 649,824.78 
			694.62,854.1 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 677.2991 827.0945)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 673.3573 838.2592)'
					>
						126
					</text>
				</g>
			</g>
			<g id='UNIT-127-U1' className={checkUnitClass('127')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u1')}
					points='623.14,759.25 624.52,760.14 631.55,749.31 632.73,750.08 637.98,742 596.73,715.22 580.82,739.73 
			619.5,764.85 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 603.0786 736.2377)'
					>
						U1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 598.2834 747.4023)'
					>
						127
					</text>
				</g>
			</g>
			<g id='UNIT-128-D' className={checkUnitClass('128')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d')}
					points='691.42,866.46 688.06,864.3 694.62,854.1 649,824.78 637.02,843.42 647.19,849.96 643.51,855.69 
			682.32,880.63 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 661.5095 851.6484)'
					>
						D
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 657.7372 862.813)'
					>
						128
					</text>
				</g>
			</g>
			<g id='UNIT-129-U1' className={checkUnitClass('129')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u1')}
					points='580.82,739.73 565.21,763.77 606.46,790.55 611.93,782.12 610.75,781.35 617.79,770.52 616.4,769.62 
			619.5,764.85 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 587.2661 760.7926)'
					>
						U1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 582.2131 771.9572)'
					>
						129
					</text>
				</g>
			</g>
			<g id='UNIT-130-D' className={checkUnitClass('130')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d')}
					points='672.93,895.24 682.32,880.63 643.51,855.69 639.85,861.39 629.92,855.01 617.92,873.67 
			663.29,902.83 669.56,893.07 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 645.697 876.2032)'
					>
						D
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 641.3369 887.3679)'
					>
						130
					</text>
				</g>
			</g>
			<g id='UNIT-131-U2' className={checkUnitClass('131')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u2')}
					points='548.97,788.79 587.66,813.91 591.29,808.31 592.67,809.21 599.7,798.38 600.88,799.14 606.46,790.55 
			565.21,763.77 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 570.4773 785.3467)'
					>
						U2
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 567.8524 796.5114)'
					>
						131
					</text>
				</g>
			</g>
			<g id='UNIT-132-D3' className={checkUnitClass('132')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='605.73,892.64 615.66,899.02 611.58,905.38 650.38,930.31 659.98,915.38 656.62,913.21 
			663.29,902.83 617.92,873.67 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 629.8623 900.7573)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 626.1666 911.922)'
					>
						132
					</text>
				</g>
			</g>
			<g id='UNIT-133-U2' className={checkUnitClass('133')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u2')}
					points='571.78,844.93 570.6,844.16 577.64,833.33 576.25,832.43 579.76,827.04 541.07,801.93 524.73,827.09 
			565.98,853.87 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 545.9142 824.5671)'
					>
						U2
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 542.0955 835.7318)'
					>
						133
					</text>
				</g>
			</g>
			<g id='UNIT-134-D3' className={checkUnitClass('134')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='630.07,953.07 633.43,955.23 643.09,940.2 604.29,915.26 600.44,921.25 590.27,914.71 578.07,933.69 
			623.68,963.01 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 607.2219 935.9164)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 603.1297 947.0811)'
					>
						134
					</text>
				</g>
			</g>
			<g id='UNIT-135-U1' className={checkUnitClass('135')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u1')}
					points='551.14,871.12 552.52,872.02 559.55,861.19 560.73,861.95 565.98,853.87 524.73,827.09 508.82,851.6 
			547.5,876.72 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 531.0787 849.121)'
					>
						U1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 526.2293 860.2856)'
					>
						135
					</text>
				</g>
			</g>
			<g id='UNIT-136-D' className={checkUnitClass('136')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d')}
					points='620.49,975.37 617.12,973.21 623.68,963.01 578.07,933.69 566.08,952.34 576.26,958.87 572.58,964.6 
			611.38,989.54 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 591.4324 960.4703)'
					>
						D
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 587.4799 971.635)'
					>
						136
					</text>
				</g>
			</g>
			<g id='UNIT-137-U1' className={checkUnitClass('137')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u1')}
					points='508.82,851.6 493.21,875.64 534.46,902.42 539.93,893.99 538.75,893.22 545.79,882.39 544.4,881.5 
			547.5,876.72 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 515.2662 873.6759)'
					>
						U1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 510.4832 884.8405)'
					>
						137
					</text>
				</g>
			</g>
			<g id='UNIT-138-D' className={checkUnitClass('138')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d')}
					points='601.99,1004.15 611.38,989.54 572.58,964.6 568.91,970.31 558.98,963.92 546.98,982.58 
			592.35,1011.74 598.62,1001.98 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 575.6199 985.0252)'
					>
						D
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 571.8593 996.1898)'
					>
						138
					</text>
				</g>
			</g>
			<g id='UNIT-139-U2' className={checkUnitClass('139')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u2')}
					points='476.97,900.66 515.66,925.78 519.29,920.18 520.67,921.08 527.7,910.25 528.88,911.01 534.46,902.42 
			493.21,875.64 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 498.4774 898.23)'
					>
						U2
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 494.4126 909.3947)'
					>
						139
					</text>
				</g>
			</g>
			<g id='UNIT-140-D3' className={checkUnitClass('140')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='534.79,1001.55 544.72,1007.93 540.64,1014.29 579.44,1039.23 589.05,1024.29 585.68,1022.12 
			592.35,1011.74 546.98,982.58 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 559.785 1009.5793)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 555.0391 1020.744)'
					>
						140
					</text>
				</g>
			</g>
			<g id='UNIT-141-U2' className={checkUnitClass('141')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u2')}
					points='499.44,956.35 498.26,955.58 505.29,944.75 503.91,943.85 507.41,938.46 468.73,913.34 
			452.39,938.51 493.64,965.29 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 473.5723 935.5397)'
					>
						U2
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 470.5397 946.7043)'
					>
						141
					</text>
				</g>
			</g>
			<g id='UNIT-142-D3' className={checkUnitClass('142')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='559.28,1062.9 562.64,1065.06 572.31,1050.02 533.51,1025.08 529.66,1031.07 519.48,1024.53 
			507.28,1043.52 552.89,1072.83 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 535.5776 1044.3761)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 531.4738 1055.5408)'
					>
						142
					</text>
				</g>
			</g>
			<g id='UNIT-143-U1' className={checkUnitClass('143')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u1')}
					points='478.8,982.54 480.18,983.44 487.21,972.6 488.39,973.37 493.64,965.29 452.39,938.51 436.47,963.02 
			475.16,988.14 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 458.7369 960.0936)'
					>
						U1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 453.5334 971.2582)'
					>
						143
					</text>
				</g>
			</g>
			<g id='UNIT-144-D' className={checkUnitClass('144')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d')}
					points='549.7,1085.2 546.34,1083.03 552.89,1072.83 507.28,1043.52 495.3,1062.16 505.47,1068.7 
			501.79,1074.42 540.59,1099.36 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 519.7881 1068.9299)'
					>
						D
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 515.2659 1080.0947)'
					>
						144
					</text>
				</g>
			</g>
			<g id='UNIT-145-U1' className={checkUnitClass('145')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u1')}
					points='436.47,963.02 420.87,987.06 462.12,1013.84 467.59,1005.41 466.41,1004.64 473.44,993.81 
			472.06,992.91 475.16,988.14 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 442.9244 984.6484)'
					>
						U1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 437.6672 995.813)'
					>
						145
					</text>
				</g>
			</g>
			<g id='UNIT-146-D' className={checkUnitClass('146')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d')}
					points='531.21,1113.97 540.59,1099.36 501.79,1074.42 498.12,1080.13 488.19,1073.75 476.2,1092.41 
			521.57,1121.57 527.84,1111.81 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 503.9756 1093.4849)'
					>
						D
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 499.6154 1104.6494)'
					>
						146
					</text>
				</g>
			</g>
			<g id='UNIT-147-U2' className={checkUnitClass('147')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u2')}
					points='404.63,1012.08 443.31,1037.2 446.95,1031.6 448.33,1032.5 455.36,1021.67 456.54,1022.43 
			462.12,1013.84 420.87,987.06 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 426.1355 1009.2025)'
					>
						U2
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 421.9208 1020.3672)'
					>
						147
					</text>
				</g>
			</g>
			<g id='UNIT-148-D3' className={checkUnitClass('148')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='464.01,1111.37 473.94,1117.76 469.86,1124.11 508.66,1149.05 518.26,1134.11 514.9,1131.95 
			521.57,1121.57 476.2,1092.41 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 488.1409 1118.0389)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 483.995 1129.2036)'
					>
						148
					</text>
				</g>
			</g>
			<g id='UNIT-149-U2' className={checkUnitClass('149')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u2')}
					points='426.64,1068.22 425.46,1067.45 432.5,1056.62 431.12,1055.72 434.62,1050.33 395.93,1025.22 
			379.59,1050.39 420.84,1077.16 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 402.2558 1048.0731)'
					>
						U2
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 397.7833 1059.2378)'
					>
						149
					</text>
				</g>
			</g>
			<g id='UNIT-150-U1' className={checkUnitClass('150')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u1')}
					points='406,1094.41 407.38,1095.31 414.41,1084.48 415.59,1085.24 420.84,1077.16 379.59,1050.39 
			363.68,1074.9 402.36,1100.01 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 387.4204 1072.627)'
					>
						U1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 381.9171 1083.7917)'
					>
						150
					</text>
				</g>
			</g>
			<g id='UNIT-151-U1' className={checkUnitClass('151')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u1')}
					points='363.68,1074.9 348.07,1098.93 389.32,1125.71 394.79,1117.28 393.61,1116.52 400.65,1105.68 
			399.26,1104.79 402.36,1100.01 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 371.6079 1097.1819)'
					>
						U1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 367.9528 1108.3464)'
					>
						151
					</text>
				</g>
			</g>
			<g id='UNIT-152-U2' className={checkUnitClass('152')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('u2')}
					points='339.05,1128.64 377.73,1153.76 381.37,1148.16 382.75,1149.06 389.78,1138.23 390.96,1138.99 
			396.54,1130.4 355.29,1103.62 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 361.6277 1127.3182)'
					>
						U2
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 357.743 1138.4829)'
					>
						152
					</text>
				</g>
			</g>
			<g id='UNIT-153-A' className={checkUnitClass('153')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('a')}
					points='961.85,612.29 977.43,595.23 945.46,566.03 926.61,586.66 948.37,606.53 951.63,602.96 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 944.8159 584.466)'
					>
						A
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 938.3331 595.6307)'
					>
						153
					</text>
				</g>
			</g>
			<g id='UNIT-154-D3' className={checkUnitClass('154')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='1045.43,672.16 1010.96,646.4 1006.34,652.57 998.21,646.49 984.92,664.27 993.05,670.35 
			993.01,670.41 1027.52,696.12 1027.52,696.12 1028.77,697.05 1036.31,686.97 1035.06,686.03 1038.04,682.05 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 1012.8742 667.379)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 1008.7284 678.5437)'
					>
						154
					</text>
				</g>
			</g>
			<g id='UNIT-155-B' className={checkUnitClass('155')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('b')}
					points='958.59,615.86 948.37,606.53 948.37,606.53 		'
				/>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('')}
					points='908.93,606.03 932.63,627.67 936.24,623.72 944.51,631.28 958.59,615.86 948.37,606.53 
			948.37,606.53 926.61,586.66 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 927.3041 604.3633)'
					>
						B
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 920.4615 615.528)'
					>
						155
					</text>
				</g>
			</g>
			<g id='UNIT-156-D3' className={checkUnitClass('156')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='993,670.42 984.87,664.34 971.46,682.27 979.6,688.35 975.25,694.16 994.06,708.22 1012.26,721.83 
			1023.11,707.32 1020.57,705.42 1027.52,696.12 993.01,670.41 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 994.1454 690.5359)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 990.1616 701.7006)'
					>
						156
					</text>
				</g>
			</g>
			<g id='UNIT-157-B' className={checkUnitClass('157')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('b')}
					points='891.04,625.62 923.01,654.82 937.3,639.18 929.02,631.62 932.63,627.67 908.93,606.03 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 909.4868 624.2611)'
					>
						B
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 902.7101 635.4258)'
					>
						157
					</text>
				</g>
			</g>
			<g id='UNIT-158-D3' className={checkUnitClass('158')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='963.25,703.65 959.15,710.02 949.06,703.54 936.74,722.72 982.23,751.96 988.73,741.86 
			992.12,744.04 1002.05,728.59 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 965.5089 724.3451)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 961.717 735.5098)'
					>
						158
					</text>
				</g>
			</g>
			<g id='UNIT-159-A' className={checkUnitClass('159')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('a')}
					points='873.55,647.7 905.53,676.89 921.15,659.79 910.93,650.46 914.28,646.79 892.53,626.92 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 891.3633 644.1585)'
					>
						A
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 884.6349 655.3232)'
					>
						159
					</text>
				</g>
			</g>
			<g id='UNIT-160-D2' className={checkUnitClass('160')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d2')}
					points='924.81,741.27 934.9,747.75 931.3,753.36 970.1,778.3 979.54,763.62 976.15,761.43 982.23,751.96 
			936.74,722.72 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 949.2983 748.7797)'
					>
						D2
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 944.692 759.9443)'
					>
						160
					</text>
				</g>
			</g>
			<g id='UNIT-161-A' className={checkUnitClass('161')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('a')}
					points='879.52,713.9 892.56,695.14 856.78,670.27 841.1,692.84 865.19,709.58 867.84,705.78 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 857.6389 686.5723)'
					>
						A
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 852.1581 697.737)'
					>
						161
					</text>
				</g>
			</g>
			<g id='UNIT-162-D2' className={checkUnitClass('162')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d2')}
					points='931.3,753.36 927.21,759.73 917.12,753.24 904.79,772.42 950.29,801.67 956.78,791.56 960.17,793.75 
			970.1,778.3 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 933.0656 773.2144)'
					>
						D2
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 929.1013 784.379)'
					>
						162
					</text>
				</g>
			</g>
			<g id='UNIT-163-B' className={checkUnitClass('163')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('b')}
					points='825.96,714.62 850.05,731.37 853.04,727.08 864.72,735.2 876.88,717.7 865.19,709.58 841.1,692.84 		
			'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 842.918 709.6473)'
					>
						B
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 835.9372 720.8119)'
					>
						163
					</text>
				</g>
			</g>
			<g id='UNIT-164-D3' className={checkUnitClass('164')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='892.87,790.97 902.96,797.46 899.36,803.06 938.16,828 947.59,813.32 944.2,811.14 950.29,801.67 
			904.79,772.42 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 916.8104 797.6494)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 912.4727 808.8141)'
					>
						164
					</text>
				</g>
			</g>
			<g id='UNIT-165-A' className={checkUnitClass('165')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('a')}
					points='809.94,737.66 845.72,762.53 859.2,743.14 847.52,735.02 850.05,731.37 825.96,714.62 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 827.5848 732.7217)'
					>
						A
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 820.8564 743.8864)'
					>
						165
					</text>
				</g>
			</g>
			<g id='UNIT-166-D3' className={checkUnitClass('166')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='892.87,813.61 888.78,819.99 878.69,813.5 866.36,832.68 911.86,861.92 918.35,851.82 921.74,854 
			931.68,838.55 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 895.1321 833.9688)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 890.9565 845.1334)'
					>
						166
					</text>
				</g>
			</g>
			<g id='UNIT-167-A' className={checkUnitClass('167')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('a')}
					points='826.4,791.68 838.76,772.46 802.1,748.9 787.25,772.02 811.93,787.88 814.44,783.99 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 804.5847 766.375)'
					>
						A
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 797.9223 777.5397)'
					>
						167
					</text>
				</g>
			</g>
			<g id='UNIT-168-D' className={checkUnitClass('168')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d')}
					points='854.44,851.23 864.53,857.71 860.93,863.32 899.73,888.26 909.16,873.58 905.77,871.39 
			911.86,861.92 866.36,832.68 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 878.9215 858.4033)'
					>
						D
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 874.9153 869.568)'
					>
						168
					</text>
				</g>
			</g>
			<g id='UNIT-169-B' className={checkUnitClass('169')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('b')}
					points='772.9,794.34 797.59,810.2 800.41,805.81 812.38,813.5 823.9,795.58 811.93,787.88 787.25,772.02 		
			'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 790.4337 789.1009)'
					>
						B
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 783.2073 800.2655)'
					>
						169
					</text>
				</g>
			</g>
			<g id='UNIT-170-D' className={checkUnitClass('170')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d')}
					points='860.93,863.32 856.83,869.69 846.74,863.2 834.41,882.38 879.91,911.63 886.4,901.52 889.8,903.71 
			899.73,888.26 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 862.6887 882.838)'
					>
						D
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 858.3403 894.0026)'
					>
						170
					</text>
				</g>
			</g>
			<g id='UNIT-171-A' className={checkUnitClass('171')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('a')}
					points='757.73,817.94 794.38,841.5 807.15,821.63 795.18,813.94 797.59,810.2 772.9,794.34 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 775.6698 811.8274)'
					>
						A
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 770.4473 822.9921)'
					>
						171
					</text>
				</g>
			</g>
			<g id='UNIT-172-D3' className={checkUnitClass('172')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='822.49,900.93 832.58,907.42 828.98,913.02 867.78,937.96 877.22,923.28 873.82,921.1 879.91,911.63 
			834.41,882.38 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 846.4336 907.2731)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 842.7496 918.4377)'
					>
						172
					</text>
				</g>
			</g>
			<g id='UNIT-173-A' className={checkUnitClass('173')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('a')}
					points='774.96,870.39 787.45,850.96 751.03,827.55 735.92,851.05 760.7,866.98 763.32,862.91 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 751.4947 844.954)'
					>
						A
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 745.0784 856.1187)'
					>
						173
					</text>
				</g>
			</g>
			<g id='UNIT-174-D3' className={checkUnitClass('174')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='822.39,922.73 818.3,929.1 808.21,922.61 795.88,941.79 841.38,971.04 847.87,960.94 851.26,963.12 
			861.19,947.67 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 824.8398 944.1371)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 820.7599 955.3018)'
					>
						174
					</text>
				</g>
			</g>
			<g id='UNIT-175-B' className={checkUnitClass('175')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('b')}
					points='721.74,873.11 748.74,890.47 751.63,885.96 761.06,892.02 772.35,874.46 760.7,866.98 760.7,866.98 
			735.92,851.05 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 738.0436 867.2764)'
					>
						B
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 731.2668 878.441)'
					>
						175
					</text>
				</g>
			</g>
			<g id='UNIT-176-D' className={checkUnitClass('176')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d')}
					points='783.96,960.34 794.05,966.83 790.44,972.43 829.25,997.37 838.68,982.69 835.29,980.51 
			841.38,971.04 795.88,941.79 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 808.6292 968.5717)'
					>
						D
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 804.689 979.7363)'
					>
						176
					</text>
				</g>
			</g>
			<g id='UNIT-177-B' className={checkUnitClass('177')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('b')}
					points='707.39,895.43 743.82,918.85 755.27,901.03 745.84,894.97 748.74,890.47 721.74,873.11 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 724.2867 889.5991)'
					>
						B
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 717.5759 900.7637)'
					>
						177
					</text>
				</g>
			</g>
			<g id='UNIT-178-D' className={checkUnitClass('178')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d')}
					points='790.44,972.43 786.35,978.81 776.26,972.32 763.93,991.5 809.43,1020.74 815.92,1010.64 
			819.32,1012.82 829.25,997.37 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 792.3965 993.0063)'
					>
						D
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 788.6481 1004.171)'
					>
						178
					</text>
				</g>
			</g>
			<g id='UNIT-179-A' className={checkUnitClass('179')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('a')}
					points='692.18,919.1 728.61,942.52 741.13,923.03 729.49,915.55 732.18,911.36 743.82,918.85 707.39,895.43 
					'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 710.2238 911.9217)'
					>
						A
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 703.5613 923.0864)'
					>
						179
					</text>
				</g>
			</g>
			<g id='UNIT-180-D3' className={checkUnitClass('180')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='752.01,1010.05 762.1,1016.53 758.5,1022.14 797.3,1047.08 806.74,1032.4 803.34,1030.21 
			809.43,1020.74 763.93,991.5 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 776.1413 1017.4414)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 771.7494 1028.6061)'
					>
						180
					</text>
				</g>
			</g>
			<g id='UNIT-181-A' className={checkUnitClass('181')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('a')}
					points='709.95,971.79 722.44,952.35 686.01,928.94 670.91,952.44 695.69,968.37 698.31,964.3 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 686.4734 945.9592)'
					>
						A
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 681.1849 957.1239)'
					>
						181
					</text>
				</g>
			</g>
			<g id='UNIT-182-D3' className={checkUnitClass('182')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='752.22,1032.89 748.12,1039.26 738.03,1032.78 725.7,1051.96 771.2,1081.2 777.69,1071.1 
			781.09,1073.28 791.02,1057.83 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 754.4044 1053.6555)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 750.6545 1064.8202)'
					>
						182
					</text>
				</g>
			</g>
			<g id='UNIT-183-B' className={checkUnitClass('183')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('b')}
					points='656.73,974.5 683.72,991.86 686.62,987.35 696.05,993.41 707.33,975.86 695.69,968.37 695.69,968.37 
			670.91,952.44 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 673.0222 968.2815)'
					>
						B
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 666.2333 979.4462)'
					>
						183
					</text>
				</g>
			</g>
			<g id='UNIT-184-D' className={checkUnitClass('184')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d')}
					points='713.78,1070.5 723.87,1076.99 720.27,1082.59 759.07,1107.53 768.51,1092.85 765.11,1090.67 
			771.2,1081.2 725.7,1051.96 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 737.5411 1078.2095)'
					>
						D
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 733.3727 1089.374)'
					>
						184
					</text>
				</g>
			</g>
			<g id='UNIT-185-B' className={checkUnitClass('185')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('b')}
					points='642.38,996.83 678.81,1020.24 690.26,1002.42 680.83,996.36 683.72,991.86 656.73,974.5 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 659.2654 990.6042)'
					>
						B
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 652.4228 1001.7689)'
					>
						185
					</text>
				</g>
			</g>
			<g id='UNIT-186-D' className={checkUnitClass('186')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d')}
					points='717.97,1081.12 713.87,1087.49 700.89,1079.15 688.56,1098.33 734.06,1127.57 740.55,1117.47 
			743.95,1119.65 753.88,1104.2 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 719.7043 1101.1987)'
					>
						D
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 715.6981 1112.3633)'
					>
						186
					</text>
				</g>
			</g>
			<g id='UNIT-187-A' className={checkUnitClass('187')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('a')}
					points='627.17,1020.5 663.59,1043.91 676.12,1024.42 664.48,1016.94 667.16,1012.76 678.81,1020.24 
			642.38,996.83 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 645.2025 1012.9269)'
					>
						A
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 638.7319 1024.0916)'
					>
						187
					</text>
				</g>
			</g>
			<g id='UNIT-188-D3' className={checkUnitClass('188')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('d3')}
					points='734.06,1127.57 688.56,1098.33 677.33,1115.81 687.42,1122.3 686.73,1123.36 686.73,1123.36 
			683.13,1128.97 721.93,1153.91 731.37,1139.23 727.97,1137.04 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 702.3992 1124.6605)'
					>
						D3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 698.6073 1135.8252)'
					>
						188
					</text>
				</g>
			</g>
			<g id='UNIT-189-A' className={checkUnitClass('189')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('a')}
					points='644.18,1073.24 656.67,1053.81 620.24,1030.4 605.13,1053.9 629.92,1069.83 632.53,1065.76 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 620.5238 1047.7239)'
					>
						A
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 613.7954 1058.8885)'
					>
						189
					</text>
				</g>
			</g>
			<g id='UNIT-190-B' className={checkUnitClass('190')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('b')}
					points='590.95,1075.96 617.95,1093.32 620.85,1088.81 630.27,1094.87 641.56,1077.31 629.92,1069.83 
			629.92,1069.83 605.13,1053.9 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 607.0727 1070.0461)'
					>
						B
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 599.438 1081.2108)'
					>
						190
					</text>
				</g>
			</g>
			<g id='UNIT-191-B' className={checkUnitClass('191')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('b')}
					points='576.61,1098.28 613.03,1121.7 624.49,1103.88 615.06,1097.82 617.95,1093.32 590.95,1075.96 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 593.3159 1092.3689)'
					>
						B
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 587.5289 1103.5336)'
					>
						191
					</text>
				</g>
			</g>
			<g id='UNIT-192-A' className={checkUnitClass('192')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('a')}
					points='561.39,1121.95 597.82,1145.37 610.34,1125.88 598.7,1118.4 601.39,1114.21 613.03,1121.7 
			576.61,1098.28 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 579.2529 1114.6915)'
					>
						A
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 572.5665 1125.8562)'
					>
						192
					</text>
				</g>
			</g>
			<g id='UNIT-193-E1' className={checkUnitClass('193')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('e1')}
					points='1130.35,730.29 1129.18,729.6 1138.28,714.13 1102.5,693.08 1098.69,699.57 1088.32,693.47 
			1077.12,712.52 1124.43,740.36 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 1105.7756 713.0965)'
					>
						E1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 1100.3428 724.2611)'
					>
						193
					</text>
				</g>
			</g>
			<g id='UNIT-194-E' className={checkUnitClass('194')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('e')}
					points='1116.49,756.51 1115.32,755.83 1124.43,740.36 1088.65,719.31 1084.83,725.79 1074.47,719.7 
			1063.26,738.74 1110.57,766.58 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 1093.6132 738.6105)'
					>
						E
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 1085.9907 749.7751)'
					>
						194
					</text>
				</g>
			</g>
			<g id='UNIT-195-E1' className={checkUnitClass('195')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('e1')}
					points='1103.23,776.38 1104.4,777.07 1110.57,766.58 1063.26,738.74 1051.81,758.22 1062.17,764.31 
			1058.88,769.91 1094.65,790.96 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 1077.8873 764.1248)'
					>
						E1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 1072.4006 775.2894)'
					>
						195
					</text>
				</g>
			</g>
			<g id='UNIT-196-E1' className={checkUnitClass('196')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('e1')}
					points='1082.87,819.27 1081.69,818.59 1090.57,802.99 1054.49,782.47 1050.77,789.01 1040.32,783.06 
			1029.39,802.27 1077.1,829.42 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 1059.0746 802.8989)'
					>
						E1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 1053.396 814.0636)'
					>
						196
					</text>
				</g>
			</g>
			<g id='UNIT-197-E' className={checkUnitClass('197')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('e')}
					points='1069.9,839.32 1071.08,839.99 1077.1,829.42 1029.39,802.27 1018.21,821.91 1028.66,827.85 
			1025.45,833.5 1061.53,854.03 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 1047.1295 828.3862)'
					>
						E
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 1039.9271 839.5509)'
					>
						197
					</text>
				</g>
			</g>
			<g id='UNIT-198-E' className={checkUnitClass('198')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('e')}
					points='1058.45,872.91 1057.27,872.24 1066.14,856.64 1030.06,836.11 1026.34,842.65 1015.89,836.7 
			1004.96,855.91 1052.67,883.06 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 1036.1373 854.8859)'
					>
						E
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 1028.8689 866.0505)'
					>
						198
					</text>
				</g>
			</g>
			<g id='UNIT-199-E1' className={checkUnitClass('199')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('e1')}
					points='1049.92,895.47 1051.1,896.14 1057.11,885.56 1009.4,858.42 998.23,878.05 1008.68,884 
			1005.47,889.65 1041.55,910.17 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 1026.6088 884.0167)'
					>
						E1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 1020.9302 895.1814)'
					>
						199
					</text>
				</g>
			</g>
			<g id='UNIT-200-E1' className={checkUnitClass('200')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('e1')}
					points='1032.46,943.1 1031.32,942.36 1041.12,927.33 1006.34,904.66 1002.23,910.97 992.16,904.41 
			980.1,922.92 1026.09,952.89 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 1009.6685 924.7159)'
					>
						E1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 1001.9675 935.8806)'
					>
						200
					</text>
				</g>
			</g>
			<g id='UNIT-201-E1' className={checkUnitClass('201')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('e1')}
					points='1018.3,962.34 1019.44,963.08 1026.09,952.89 980.1,922.92 967.76,941.85 977.84,948.41 
			974.29,953.86 1009.07,976.52 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 993.4354 949.1505)'
					>
						E1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 987.5825 960.3152)'
					>
						201
					</text>
				</g>
			</g>
			<g id='UNIT-202-E1' className={checkUnitClass('202')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('e1')}
					points='984.26,1000.19 983.17,999.38 993.89,984.99 960.6,960.2 956.1,966.24 946.46,959.06 933.26,976.78 
			977.29,1009.56 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 961.9321 981.4456)'
					>
						E1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 954.8732 992.6102)'
					>
						202
					</text>
				</g>
			</g>
			<g id='UNIT-203-E' className={checkUnitClass('203')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('e')}
					points='968.93,1018.51 970.02,1019.32 977.29,1009.56 933.26,976.78 919.77,994.91 929.41,1002.09 
			925.54,1007.3 958.83,1032.08 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 946.6456 1004.7654)'
					>
						E
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 937.8171 1015.9301)'
					>
						203
					</text>
				</g>
			</g>
			<g id='UNIT-204-E' className={checkUnitClass('204')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('e')}
					points='925.54,1007.3 921.37,1012.9 911.72,1005.72 898.35,1023.68 942.38,1056.46 949.6,1046.75 
			948.52,1045.94 958.83,1032.08 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 929.5775 1028.0853)'
					>
						E
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 920.3408 1039.25)'
					>
						204
					</text>
				</g>
			</g>
			<g id='UNIT-205-E1' className={checkUnitClass('205')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('e1')}
					points='884.98,1041.64 894.62,1048.82 890.48,1054.39 923.77,1079.18 934.25,1065.11 935.34,1065.92 
			942.38,1056.46 898.35,1023.68 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 910.7272 1051.405)'
					>
						E1
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 903.6263 1062.5696)'
					>
						205
					</text>
				</g>
			</g>
			<g id='UNIT-206-E2' className={checkUnitClass('206')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('e2')}
					points='885.6,1132.44 884.51,1131.62 895.41,1117.19 861.88,1091.86 857.39,1097.8 848.6,1091.16 
			835.13,1108.99 838.93,1111.85 843.92,1115.63 843.92,1115.63 878.54,1141.78 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 864.1693 1113.7422)'
					>
						E2
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 856.8764 1124.9069)'
					>
						206
					</text>
				</g>
			</g>
			<g id='UNIT-207-E3' className={checkUnitClass('207')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('e3')}
					points='843.92,1115.63 843.92,1115.63 839.59,1121.36 830.8,1114.72 817.38,1132.49 860.78,1165.28 
			868.31,1155.31 867.22,1154.49 877.45,1140.96 878.54,1141.78 878.54,1141.78 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 848.1235 1136.7223)'
					>
						E3
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 839.3068 1147.887)'
					>
						207
					</text>
				</g>
			</g>
			<g id='UNIT-208-E2' className={checkUnitClass('208')}>
				<polygon
					className={SCSS.Hot}
					onClick={() => onclickUnit('e2')}
					points='803.76,1150.51 812.55,1157.15 808.06,1163.09 841.59,1188.43 852.6,1173.85 853.69,1174.67 
			860.78,1165.28 817.38,1132.49 		'
				/>
				<g className={SCSS.Label}>
					<text
						className={SCSS.PlanName}
						transform='matrix(1 0 0 1 828.5139 1159.7025)'
					>
						E2
					</text>
					<text
						className={SCSS.Number}
						transform='matrix(1 0 0 1 821.413 1170.8672)'
					>
						208
					</text>
				</g>
			</g>
		</g>
	);
};

export default Units;
